import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Wrapper, H, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'

const GlobalStyle = createGlobalStyle`
  footer {
    border-top: 1px solid ${COLOR.GREY.LIGHT};
  }
`

const StyledOl = styled.ol`
  list-style: disc;
  padding-left: 1em;
`

const SupportedBrowsers = (): React.ReactElement => (
  <DefaultLayout
    title="Supported Browsers"
    description="You need to be on an up-to-date web browser to use Farewill’s online
        services."
    headerType="compact"
    footerType="compact"
    alignContent="start"
    headerColor={COLOR.WHITE}
  >
    <GlobalStyle />
    <Wrapper container>
      <H tag="h1" size="XL" decorative>
        Supported Browsers
      </H>
      <P size="L" maxWidth={500}>
        You need to be on an up-to-date web browser to use Farewill’s online
        services.
      </P>
      <P>
        We design and build our services to be fully supported on the latest two
        versions of:
      </P>
      <StyledOl>
        <li>
          <a href="https://www.google.com/chrome/">Google Chrome</a>
        </li>
        <li>
          <a href="http://www.apple.com/safari/">Apple Safari</a>
        </li>
        <li>
          <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
            Microsoft Edge
          </a>
        </li>
        <li>
          <a href="https://www.samsung.com/us/support/owners/app/samsung-internet">
            Samsung Internet
          </a>
        </li>
        <li>
          <a href="http://www.mozilla.org/firefox/">Mozilla Firefox</a>
        </li>
      </StyledOl>
      <P>
        For best results visit Farewill in the latest version of{' '}
        <a href="https://www.google.com/chrome/">Google Chrome</a>.
      </P>
    </Wrapper>
  </DefaultLayout>
)

export default SupportedBrowsers
